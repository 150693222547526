<template>
  <div>
      <b-loading :is-full-page="true" :active="selectedQuestion==null"></b-loading>
      <div class="columns">
        <div class="column">
          <div
              class="card is-shadowless is-bordered"
              style="border: 2px dashed rgba(0, 0, 0, 0.13); padding: 16px; "
              v-if="selectedQuestion!=null"
          >
            <span class="title">{{ $t('learning.learning') }}</span>
            <span class="subtitle" style="margin-left: 8px;">{{ $t('learning.question') }} #{{
                selectedQuestion.id
              }}</span>
            <b-button
                class="is-small"
                rounded
                style="float: right; margin-left: 8px;"

                @click="loadQuestion(selectedQuestion.id+1)"
            >{{ $t('lesson.nextQuestion') }}
            </b-button>
            <b-button
                class="is-small"
                rounded
                style="float: right; margin-left: 8px;"
                @click="loadQuestion(selectedQuestion.id-1)"
            >{{ $t('lesson.previousQuestion') }}
            </b-button>
            <div class="select is-small" style="float: right">
              <select v-model="selectedCategory" @change="onCategoryChange">
                <option v-for="(cat, index) in questionCategories" :value="cat.category">
                  {{ cat.category }}
                </option>
              </select>
            </div>
            <div
                style="margin-bottom: 8px; padding-top: 8px; font-style: italic; text-align: justify; text-justify: inter-word; margin-right: 16px;">
              {{ selectedQuestion.question_text }}
            </div>
            <div v-for="opt in ['a', 'b', 'c', 'd']"
                 :class="{card:true, 'bumped-on-hover':true,'answer-box':true,
                 'answer-box-correct':selectedQuestion[`answer_${opt}_status`]===1&&selectedQuestion.answer[opt]&&checked,
                 'answer-box-error':((selectedQuestion[`answer_${opt}_status`]===0&&selectedQuestion.answer[opt]) || (selectedQuestion[`answer_${opt}_status`]===1 && !selectedQuestion.answer[opt]))&&checked,
                 'answer-box-selected':selectedQuestion.answer[opt]&&!checked}"
                 @click="changeAnswer(opt)">{{
                opt.toUpperCase()
              }}. {{ selectedQuestion['answer_' + opt + '_text'] }}
            </div>

            <div style="text-align: right">
              <b-button
                  class="mr-2 mt-3"
                  rounded
                  style=""
                  @click="checkAnswer"
                  :disabled="checked">{{ $t('learning.check') }}
              </b-button>
            </div>

          </div>

        </div>
        <div class="column is-one-fifth" v-if="questionCategories.length>0">
          <div
              class="card is-shadowless is-bordered"
              style="border: 2px dashed rgba(0, 0, 0, 0.13); padding: 16px; height: 500px;">
            <div style="margin-top: 8px; clear: both">
              <div style="margin-top: 8px;">
                <div v-for="questionId in selectedQuestionIds.slice(gridPage*50).slice(0,50)"
                     style="width: 32px; height: 32px; text-align: center; color: white; cursor: pointer; float:left; margin-right: 8px;  margin-bottom: 8px; padding-top: 4px;"
                     :class="{'has-background-grey':!skills.hasOwnProperty(questionId)||skills[questionId]==='not_seen',
                     'has-background-danger':skills.hasOwnProperty(questionId.toString())&&skills[questionId.toString()]==='bad','has-background-success':skills.hasOwnProperty(questionId.toString())&&skills[questionId.toString()]==='good'}"
                     @click="loadQuestion(questionId)"
                >{{ questionId }}
                </div>
              </div>
            </div>
            <b-button
                class="is-small"
                rounded
                style="float: right"
                @click="gridPage=gridPage+1"
                :disabled="selectedQuestionIds.slice(gridPage*50).slice(0,50).length<50"
            >{{ $t('learning.next') }}
            </b-button
            >
            <b-button
                class="is-small mr-2"
                rounded
                style="float: left"
                @click="gridPage=gridPage-1"
                :disabled="gridPage==0"
            >{{ $t('learning.previous') }}
            </b-button
            >
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import HomeWrapper from "../components/HomeWrapper";

export default {
  name: "Learning",
  components: {HomeWrapper},
  mounted() {
    this.load();
  },
  data() {
    return {
      questionCategories: [],
      selectedQuestion: null,
      selectedCategory: null,
      gridPage: 0,
      skills: [],
      checked: false
    };
  },
  methods: {
    changeAnswer(opt) {
      this.checked = false;
      this.selectedQuestion.answer[opt] = !this.selectedQuestion.answer[opt];
    },
    async load() {
      const profile = await this.$api.getProfile();
      this.questionCategories = await this.$api.getQuestionIds();
      this.questionCategories = this.questionCategories.filter(x => [...x.category].filter(l => l === '/').length < 2).filter(x => {
        if (x == null) return false;
        return profile.is_inspector || profile.quiz_categories.includes(x.category.split('/')[0]) || profile.quiz_categories.includes(x.category);
      });
      this.selectedCategory = this.questionCategories[0].category;
      await this.loadQuestion(1);
      this.skills = await this.$api.indexSkills();
    },
    async loadQuestion(id) {
      this.checked = false;
      const question = await this.$api.getQuestion(id);
      question.answer = {};
      for (const opt of ['a', 'b', 'c', 'd'])
        question.answer[opt] = false;
      this.selectedQuestion = question;
    },
    async onCategoryChange() {
      this.gridPage = 0;
      await this.loadQuestion(this.questionCategories.find(x => x.category === this.selectedCategory).question_ids[0]);
    },
    async checkAnswer() {
      this.checked = true;
      let mode = 'bad';
      if (this.selectedQuestion[`answer_a_status`] === (this.selectedQuestion.answer['a'] ? 1 : 0)
          && this.selectedQuestion[`answer_b_status`] === (this.selectedQuestion.answer['b'] ? 1 : 0)
          && this.selectedQuestion[`answer_c_status`] === (this.selectedQuestion.answer['c'] ? 1 : 0)
          && this.selectedQuestion[`answer_d_status`] === (this.selectedQuestion.answer['d'] ? 1 : 0))
        mode = 'good';
      this.$api.saveSkill(this.selectedQuestion.id, mode);
      this.skills[this.selectedQuestion.id.toString()] = mode;
      this.$forceUpdate();
    }
  },
  computed: {
    selectedQuestionIds() {
      return this.questionCategories.find(x => x.category === this.selectedCategory).question_ids;
    }
  }
}
</script>

<style scoped>

.bumped-on-hover:hover {
  filter: brightness(90%);
}

.answer-box {
  margin-bottom: 8px;
  padding: 8px;
  cursor: pointer;
}

.answer-box-correct {
  background: rgba(72, 199, 142, 0.8);
  overflow: hidden;
  z-index: 2;
}

.answer-box-error {
  background: rgba(241, 70, 104, 0.8);
  overflow: hidden;
  z-index: 2;
}

.answer-box-selected {
  background: rgba(52, 115, 220, 0.8);
  overflow: hidden;
  z-index: 2;
}

@media screen and (min-width: 600px) {
  .mobile-visible {
    display: none;
  }

  .desktop-visible {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .mobile-visible {
    display: block;
  }

  .desktop-visible {
    display: none;
  }

  .previous-question-button {
    float: left !important;
  }
}
</style>
